.alignCenter {
    display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}

.imageContainer {
    height:100vh;
    width:100%;
}